// AboutUsPage.scss

// Variables
$primary-red: #ff3131;
$primary-black: #000000;
$secondary-gray: #808080;
$background-white: #FFFFFF;

// General Styles
body {
    font-family: Poppins, sans-serif !important;
    background-color: $background-white;
    color: $primary-black;
}

.header-text {
    font-size: 4rem;
    line-height: 1.2;
    color: $primary-red;

    // Responsive adjustments
    @media (max-width: 1200px) {
        font-size: 3.5rem;
    }

    @media (max-width: 992px) {
        font-size: 3rem;
    }

    @media (max-width: 768px) {
        font-size: 2.5rem;
    }

    @media (max-width: 576px) {
        font-size: 2rem;
    }
}

.header-subtext {
    font-size: 5rem;
    line-height: 0.5;
    color: $primary-red;

    // Responsive adjustments
    @media (max-width: 1200px) {
        font-size: 4.5rem;
    }

    @media (max-width: 992px) {
        font-size: 4rem;
    }

    @media (max-width: 768px) {
        font-size: 3.5rem;
    }

    @media (max-width: 576px) {
        font-size: 3rem;
    }
}

.wavy-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    z-index: -1; // Ensure it stays behind other content
}

h1,
h2,
h3 {
    color: $primary-red !important;
    font-weight: bold;
}

p {
    color: $primary-black !important;
}

.subtitle {
    color: $secondary-gray !important;
    font-weight: normal;
}

.list {
    color: $primary-black !important;
    padding-left: 1rem;
    list-style-type: disc !important;
}

.contact-info {
    color: $primary-red !important;
    font-weight: bold;
}

.header {
    background-color: $background-white !important;
    text-align: center;
    padding: 2rem 0;
}

.section-title {
    color: $primary-red !important;
    font-weight: bold;
    margin-bottom: 1rem;
}

.section-description {
    color: $secondary-gray !important;
    font-weight: normal;
    margin-bottom: 2rem;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.container {
    padding: 2rem;
}