.sidebar-open .sidebar {
  width: 290px; //250
}

.sidebar-closed .sidebar {
  width: 60px;
}

.sidebar-open .cm-content-wrapper {
  // margin-left: 250px;
  transition: margin-left 0.3s;
}

.sidebar-closed .cm-content-wrapper {
  margin-left: 60px;
  transition: margin-left 0.3s;
}

@media (max-width: 768px) {
  .sidebar-open .cm-content-wrapper {
    margin-left: 0;
  }

  .sidebar-closed .cm-content-wrapper {
    margin-left: 0;
  }
}