/* Header */

#basic-nav-dropdown {
  color: #ff3131 !important;
}

.cm-text-color {
  color: #000000 !important;
}

.cm-text-color:hover {
  color: #dc3545 !important;
}