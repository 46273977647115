/* src/Sidebar.css */
.sidebar {
    width: 290px;
    transition: width 0.3s;
}

.sidebar.closed {
    width: 60px;
}

.sidebar .nav-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar.closed .nav-link span {
    display: none;
}

/* Ensure the sidebar is hidden by default on smaller screens */
@media (max-width: 991px) {
    .sidebar {
        width: 0;
        display: none;
    }

    // .sidebar.open {
    //     width: 250px;
    //     display: block;
    // }
}

/* Offcanvas Sidebar */
.offcanvas {
    width: 250px;
}

.cm-text-color {
    color: #000000;
}

.cm-text-color:hover {
    color: #dc3545;
}

// .sidebar .cm-text-color {
//     white-space: normal; // Allows text to break to the next line
//     word-wrap: break-word; // Ensures long words are properly wrapped
//     overflow: hidden; // Prevents overflow of the container
// }