body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

$theme-colors: (
    "cm-primary": #4285f4,
    "cm-light-primary": #f0f6fc,
    "cm-active-primary": #6cb4e8,
    "cm-success": #6ce887,
    "cm-danger": #ff3131,
    "cm-gray": #808080,

    "cm-dark": #4d4d4d,
    "cm-light": #e7f0ff4d,
    "cm-dark-grey": #373737,
    "cm-medium-grey": #7f7f7f,
    "cm-light-grey": #bbbec0,
    "cm-icon-grey": #c9c9c9,
    "cm-accent-grey": #e3e3e2,
    "cm-other-grey": #d1d2d2,
    "near-black-accent": #030303,
);

// Dynamically generated CSS
@each $color in $theme-colors {
    .text-#{nth($color, 1)} {
        color: nth($color, 2);
    }

    .bg-#{nth($color, 1)} {
        background: nth($color, 2);
    }
}

@function getColor($name) {
    @return map-get($theme-colors, $name);
}

.ant-table-thead {
    .ant-table-cell {
        background-color: #dc3545 !important;
        color: #ffffff !important;
        font-weight: 900;
        font-size: 14px;
    }
}

.ant-table table {
    font-size: 16px;
}

.ant-table-thead .ant-table-cell {
    white-space: nowrap !important;
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    justify-content: center !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.375rem !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-pagination .ant-pagination-item-active {
    border-color: #dc3545;
}

.ant-select-dropdown {
    z-index: 1500;
}

.translate-widget {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

.cursor-pointer {
    cursor: pointer;
}

.ant-picker-dropdown {
    z-index: 10000 !important;
    /* Ensure it appears above other elements */
}