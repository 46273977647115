.border-cell {
    border: 1px solid #000000;
    /* Black borders for all sides */
    box-sizing: border-box;
    /* Ensure borders are included in the element’s total width and height */
    border-collapse: collapse;
}

.pa-b {
    padding-bottom: 1.80rem !important;
    padding-top: 1.80rem !important;
    padding-left: .5rem !important;
    padding-right: .5rem !important;
}

.display-1 {
    font-size: 4rem;
    /* Adjust the size of the rating */
}

/* Ensuring each row in the left column has equal height */
.table-column .row {
    height: calc(100% / 5);
}

.h-100 {
    height: 100%;
}

.truncate-text {
    display: block; // Ensure it's a block to apply width constraints
    white-space: nowrap; // Prevents the text from wrapping to the next line
    overflow: hidden; // Hides the overflow text
    text-overflow: ellipsis; // Adds the ellipsis when text overflows
    max-width: 100%; // Ensure it doesn't go beyond the column size
}

.text-truncate {
    position: relative;
}

.text-truncate:hover::after {
    content: attr(title); // Display the full text on hover using the title attribute
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75); // Dark background for the tooltip
    color: white;
    padding: 5px;
    border-radius: 5px;
    white-space: normal; // Allows multiline text in the tooltip
    max-width: 300px; // Set a maximum width for the tooltip
    z-index: 100;
    left: 0;
    top: 100%;
}